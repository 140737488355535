/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import "firebase/app"
// import "firebase/firestore"
import "./src/assets/tailwind.css"
